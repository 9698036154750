import { useState, useEffect, useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { useNavigate } from "react-router-dom";
import BaseLayout from "layouts/sections/components/BaseLayout";

import LinearProgress from "@mui/material/LinearProgress";

import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
//import DefaultFooter from "examples/Footers/DefaultFooter";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
//import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import { DataGrid, useGridApiRef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import projectsTableData from "pages/Products/sections/data/pagesData"; // eslint-disable-line  no-unused-vars
// Routes

//import footerRoutes from "footer.routes";
import { DataContext } from "../../context";
//import MKTypography from "components/MKTypography"; // eslint-disable-line  no-unused-vars

function Home() {
  const { products, fetchJobQC, OC } = useContext(DataContext); // eslint-disable-line  no-unused-vars
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const navigateTo = (item) => {
    navigate(`/Opening-Check-List/${id}/${item}`); // Navigate to '/about' route
  };
  /*
  const fetchData1 = async () => {
    try {
      // const { columns, rows } = await projectsTableData({ products });
      // setTableData({ columns, rows });
      setLoading(false);
    } catch (error) {
      //  console.error("Error fetching data:", error);
      setLoading(false);
    }
  }; // eslint-disable-line  no-unused-vars*/

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const data = await fetchJobQC(id);
          const items = Object.values(data).map((item, index) => ({
            id: index + 1, // Add a unique ID for the DataGrid
            ...item,
          }));
          setRows(items);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const columns = [
    {
      field: "OC_ID",
      headerName: "OC ID",
      width: 150,
      renderCell: (params) => (
        <MKButton
          variant="gradient"
          color="info"
          startIcon={
            <Icon size="sm" sx={{ fontWeight: "bold" }}>
              edit
            </Icon>
          }
          onClick={() => navigateTo(params.row.OC_ID)} // Example edit function
        >
          OCL-{params.row.OC_ID}
        </MKButton>
      ),
    },
    { field: "Check_Date", headerName: "Check Date", width: 100 },
    { field: "Elevation_Num", headerName: "Elevation Num", width: 100 },
    {
      field: "ContainsRejected",
      headerName: "Status",
      width: 100,
      renderCell: (params) =>
        params.row.ContainsRejected == 1 ? (
          <MKButton
            variant="gradient"
            color="warning"
            onClick={() => navigateTo(params.row.OC_ID)}
          ></MKButton>
        ) : (
          <MKButton
            variant="gradient"
            color="success"
            onClick={() => navigateTo(params.row.OC_ID)}
          ></MKButton>
        ),
    },
    { field: "employeeU", headerName: "Author", width: 100 },
    { field: "SeriesDescription", headerName: "Check Series", width: 100 },
    {
      field: "Check_Selected_Contacts",
      headerName: "Primary Contact",
      width: 100,
      renderCell: (params) => {
        // Assuming Check_Selected_Contacts contains the JSON object
        const contact = JSON.parse(params.row?.Check_Selected_Contacts || '{ "Primary": "" }');
        // Return the specific parameter you want to display
        return contact?.Primary;
      },
    },
  ];

  const Editrow = () => (
    <Card>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          p: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8} md={5} lg={3}>
            <GridToolbarQuickFilter />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );

  return (
    <BaseLayout
      title={OC[1]?.JobName || "Jobname"}
      breadcrumb={[
        { label: "Opening Checklist Index", route: `/Opening-Check-List/${id}` },
        { label: OC[1]?.JobName },
      ]}
    >
      <MKBox minHeight="15vh" width="100%"></MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -16,
          mb: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Container sx={{ mt: 6 }}>
            <div>
              <DataGrid
                rows={rows || {}}
                columns={columns.map((column) => ({
                  ...column,
                  minWidth: 150, // Adjust as needed
                }))}
                autoHeight
                disableColumnReorder={true}
                sortingOrder={["asc", "desc"]}
                checkboxSelection={false} // Disable selection
                disableSelectionOnClick={true} // Disable cell selection
                disableColumnMenu={true} // Disable column menu to prevent highlighting
                disableColumnSelector={true}
                apiRef={apiRef}
                loading={rows.length == 0}
                sx={{
                  "& .MuiDataGrid-row.Mui-hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                  },
                  "& .MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "rgba(0, 0, 0, 0.26)",
                  },
                  "& .MuiDataGrid-row.Mui-selected:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.18)",
                  },
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                  toolbar: Editrow,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </div>
          </Container>
        )}
      </Card>
    </BaseLayout>
  );
}

export default Home;
