import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ExternalRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = "https://waltekltd.com/";
  }, [navigate]);

  return null;
};

export default ExternalRedirect;
