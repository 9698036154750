// components/StyledTextarea.js

import React from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { styled } from "@mui/material/styles";

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.divider,
  "&:focus": {
    borderColor: theme.palette.info.main,
    outline: "none",
    borderWidth: "medium",
  },
}));

const CustomTextarea = (props) => {
  return (
    <StyledTextarea
      aria-label="custom styled"
      minRows={4}
      placeholder="Type your text here..."
      {...props}
    />
  );
};

export default CustomTextarea;
