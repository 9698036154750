/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */

import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import {
  PDFDocument,
  rgb,
  PDFName,
  PDFHexString,
  degrees,
  PDFString,
  PDFNull,
  PDFNumber,
} from "pdf-lib";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import "pdfjs-dist/web/pdf_viewer.css";
import BaseLayout from "layouts/sections/components/BaseLayout";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import "../Batch Link/style.css";

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js";

const PdfAnnotator = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orgPDFName, setPDFName] = useState("");
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState("Firing Up The Engines");
  const [files, setFiles] = useState([]);
  const dropzoneRef = useRef(null);

  const loadPdf = async (url) => {
    const loadingTask = pdfjsLib.getDocument(url);
    const pdf = await loadingTask.promise;
    setPdfDoc(pdf);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles[0]);
    setPDFName(event.target.files[0].name);
    if (file) {
      const url = URL.createObjectURL(file);
      setPdfUrl(url);
      await loadPdf(url);

      LoadingProcessedPDF(url);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropzoneRef.current.classList.remove("dragover");
    const droppedFiles = e.dataTransfer.files[0];
    setPDFName(droppedFiles.name);
    setFiles(droppedFiles);
    if (droppedFiles) {
      const url = URL.createObjectURL(droppedFiles);
      setPdfUrl(url);
      await loadPdf(url);

      LoadingProcessedPDF(url);
    }
  };

  const LoadingProcessedPDF = async (url) => {
    setIsLoading(true);
    setProgress(0);

    try {
      await processPdf(url, setProgress);
    } finally {
      setIsLoading(false);
    }
  };

  const processPdf = async (url, updateProgress) => {
    try {
      const totalSteps = 4; // Number of significant steps in this function
      let currentStep = 0;

      if (!url) {
        console.error("PDF URL is invalid.");
        return;
      }
      const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;
      // Create an array of text to show at each step
      const texts = [
        [
          "Seeking out the hidden words... are they hiding from us?",
          "Annotations, where art thou?",
          "Diving deep into the PDF... found a treasure chest! Oh wait, it's just text.",
          "Annotation detectives on the case... clues are being gathered.",
          "Finding those notes like a pro... or at least trying!",
          "Annotation search party: deployed.",
          "Is it an annotation or just a doodle? Let's find out!",
        ],
        [
          "Loading the PDF... it's a page-turner!",
          "Unfolding the digital paper... stay tuned.",
          "PDF is almost ready, just giving it a quick polish.",
          "Loading... the suspense is real!",
          "Bringing the PDF to life... one byte at a time.",
          "Loading the PDF... it's worth the wait, promise!",
          "Your PDF is being loaded... preparing for a smooth read.",
        ],
        [
          "Erasing history... but only the annotations!",
          "Making those notes disappear... like magic!",
          "Deleting annotations... shh, it's a clean slate now.",
          "Sweeping up the annotations... don't worry, the pages are safe!",
          "Clearing the clutter... annotations, begone!",
          "Annotations? What annotations? Poof!",
          "Deleting annotations... it's like a virtual eraser.",
        ],
        [
          "Building buttons... they're small but mighty!",
          "Crafting the perfect buttons... just for you!",
          "Generating buttons... they'll be clickable in no time!",
          "Buttons are on the way... and they're looking sharp!",
          "Assembling buttons... ready to push?",
          "Creating buttons... because who doesn't love clicking?",
          "Your buttons are in the oven... almost done baking!",
          "The 'Power Button' on every device... it's small, but it holds the key to everything.",
          "The 'Start' button in Windows has been iconic since Windows 95... and it's still going strong.",
          "The 'Elevator Close Door Button' - does it really work? That's a mystery for the ages.",
          "When push buttons were first invented in the late 19th century many people were afraid of them, this was more due to Electricity than the button though",
        ],
      ];

      // Function to update progress text every 3 seconds
      const updateProgressText = (alternateTexts) => {
        let alternateIndex = getRandomInt(0, alternateTexts.length);
        return setInterval(() => {
          setProgressText(alternateTexts[alternateIndex]);
          alternateIndex = (alternateIndex + 1) % alternateTexts.length; // Cycle through the alternate texts
        }, 3000);
      };

      // Step 1: Find Annotations
      let intervalId = updateProgressText(texts[0]);
      setProgressText("Finding Annot Text");
      const annotations = await extractAnnotations(url);
      clearInterval(intervalId);
      currentStep++;
      updateProgress((currentStep / totalSteps) * 100);

      // Step 2: Fetch existing PDF bytes
      intervalId = updateProgressText(texts[1]);
      setProgressText("Loading PDF into Editor");
      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
      clearInterval(intervalId);
      currentStep++;
      updateProgress((currentStep / totalSteps) * 100);

      // Step 3: Delete rectangle annotations
      intervalId = updateProgressText(texts[2]);
      setProgressText("Deleting All Annots");
      const updatedPdfBytes = await deleteRectAnnotations(existingPdfBytes);
      clearInterval(intervalId);
      currentStep++;
      updateProgress((currentStep / totalSteps) * 100);

      // Step 4: Create PDF with JS actions
      intervalId = updateProgressText(texts[3]);
      setProgressText("Generating Buttons");
      const modifiedPdfBytes = await createPdfWithJsActions(updatedPdfBytes, annotations);
      clearInterval(intervalId);
      currentStep++;
      updateProgress((currentStep / totalSteps) * 100);

      // Final Step: Download the modified PDF
      downloadPdf(modifiedPdfBytes);
      setProgressText("Batch Linker Complete");
    } catch (error) {
      console.error("Error processing PDF:", error);
    }
  };

  const extractAnnotations = async (pdfUrl) => {
    const loadingTask = pdfjsLib.getDocument(pdfUrl);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;
    const annotations = [];

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const pageAnnotations = await page.getAnnotations();
      annotations.push({
        page: pageNum,
        annotations: pageAnnotations,
      });
    }
    return annotations;
  };

  const deleteRectAnnotations = async (existingPdfBytes) => {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();

    for (const page of pages) {
      const pageNode = page.node;

      const annotsArray = pageNode.get(PDFName.of("Annots"))?.value || [];
      const existingAnnots = annotsArray.map((ref) => pdfDoc.context.lookup(ref));

      const updatedAnnots = existingAnnots.filter((annot) => {
        const subtype = annot.dict.get(PDFName.of("Subtype"));
        return subtype?.toString() !== "Square";
      });

      pageNode.set(PDFName.of("Annots"), pdfDoc.context.obj(updatedAnnots));
    }

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  };

  const createOutlineItem = (pdfDoc, title, parent, nextOrPrev, page, isLast = false) =>
    pdfDoc.context.obj({
      Title: PDFString.of(title),
      Parent: parent,
      [isLast ? "Prev" : "Next"]: nextOrPrev,
      Dest: pdfDoc.context.obj([page.ref, PDFName.of("XYZ"), PDFNull, PDFNull, PDFNull]),
    });

  const addBookmarks = (pdfDoc, BookMarks) => {
    const pageRefs = pdfDoc.getPages();
    const outlinesDictRef = pdfDoc.context.nextRef();
    const refArray = [];
    const itemArray = [];

    // Create references for each outline item
    for (let i = 0; i < BookMarks.length; i++) {
      refArray[i] = pdfDoc.context.nextRef();
    }

    // Create outline items and push them to itemArray
    for (let i = 0; i < BookMarks.length; i++) {
      const nextRef = i < BookMarks.length - 1 ? refArray[i + 1] : PDFNull;
      const prevRef = i > 0 ? refArray[i - 1] : PDFNull;
      const isLast = i === BookMarks.length - 1;

      const outlineItem = createOutlineItem(
        pdfDoc,
        BookMarks[i],
        outlinesDictRef,
        nextRef,
        pageRefs[i],
        isLast
      );

      itemArray.push(outlineItem);
    }

    // Create the outlines dictionary
    const outlinesDict = pdfDoc.context.obj({
      Type: PDFName.of("Outlines"),
      First: refArray[0],
      Last: refArray[refArray.length - 1],
      Count: PDFNumber.of(refArray.length),
    });

    // Assign the outlines dictionary
    pdfDoc.context.assign(outlinesDictRef, outlinesDict);

    // Assign each outline item
    for (let i = 0; i < BookMarks.length; i++) {
      pdfDoc.context.assign(refArray[i], itemArray[i]);
    }

    // Set the catalog entry for the outlines
    pdfDoc.catalog.set(PDFName.of("Outlines"), outlinesDictRef);
  };
  const removeBookmarks = async (pdfDoc) => {
    const catalog = pdfDoc.catalog;
    catalog.delete(PDFName.of("Outlines"));
  };
  const createPdfWithJsActions = async (existingPdfBytes, annotations) => {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const form = pdfDoc.getForm();
    const PageNumbers = [];
    await removeBookmarks(pdfDoc);
    for (const pageInfo of annotations) {
      const page = pages[pageInfo.page - 1];

      // Get the page dimensions
      const { width, height } = page.getSize();

      var q = [];

      // Check for page rotation and adjust accordingly
      let rota = degrees(0);
      var buttonWidthDim = width / 14;
      var buttonHeightDim = height / 14;
      var BackbuttonX = 0;
      var BackbuttonY = 0;
      var ForbuttonX = 0;
      var ForbuttonY = 0;
      var TopbuttonX = 0;
      var TopbuttonY = 0;
      if (width > height) {
        ///

        q = [width - buttonWidthDim, 0, width, buttonHeightDim];
        BackbuttonX = q[0] + 40;
        BackbuttonY = q[1] + 20;
        ForbuttonX = q[0] + 100;
        ForbuttonY = q[1] + 20;
        TopbuttonX = q[0] + 100;
        TopbuttonY = q[1] + 20;

        // Page is in landscape mode
        rota = degrees(0);
      } else {
        // Page is in portrait mode

        q = [0, 0, buttonWidthDim + 15, buttonHeightDim + 15];
        BackbuttonX = q[0];
        BackbuttonY = q[0] + buttonWidthDim + 60;
        ForbuttonX = q[0];
        ForbuttonY = q[0] + buttonWidthDim;
        TopbuttonX = q[0] + 5;
        TopbuttonY = q[0] + buttonWidthDim + 5;
        rota = degrees(270);
      }

      /*     const Testbutton = form.createButton(`GoBac${Math.random()}`);

      Testbutton.addToPage("Search Area", page, {
        x: Math.min(q[0]),
        y: Math.min(q[1]),
        width: Math.abs(q[2] - q[0]),
        height: Math.abs(q[3] - q[1]),
        borderColor: rgb(0, 1, 0),
        borderWidth: 2,

      });
*/
      for (const annot of pageInfo.annotations) {
        if (
          annot.subtype === "Square" &&
          annot.contentsObj.str.length <= 9 &&
          annot.contentsObj.str.includes("-") &&
          /^[A-Za-z]+-\d{2,}[A-Za-z0-9]*$/.test(annot.contentsObj.str.trim())
        ) {
          if (
            annot.rect[0] >= q[0] &&
            annot.rect[1] >= q[1] &&
            annot.rect[2] <= q[2] &&
            annot.rect[3] <= q[3]
          ) {
            // const Backbutton = form.createButton(`GoBack${annot.contentsObj.str.trim()}`);
            // const Forwardbutton = form.createButton(`GoForward${annot.contentsObj.str.trim()}`);
            const Topbutton = form.createButton(`Top${annot.contentsObj.str.trim()}`);
            /*
            Backbutton.addToPage("Go Back", page, {
              x: BackbuttonX,
              y: BackbuttonY,
              width: 50,
              height: 20,
              borderColor: rgb(1, 1, 0),
              borderWidth: 2,
              rotate: rota,
            });
            Forwardbutton.addToPage("Go Forward", page, {
              x: ForbuttonX,
              y: ForbuttonY,
              width: 50,
              height: 20,
              borderColor: rgb(1, 1, 0),
              borderWidth: 2,
              rotate: rota,
            });
*/
            Topbutton.addToPage("Top", page, {
              x: TopbuttonX,
              y: TopbuttonY,
              width: 50,
              height: 20,
              borderColor: rgb(1, 1, 0),
              borderWidth: 2,
              rotate: rota,
            });
            PageNumbers.push(annot.contentsObj.str.trim());

            const helloWorldScript = `getField("Top${PageNumbers[0]}").setFocus();zoomType=zoomtype.fitP`;
            /*  const GoBackScript = `try {
      app.goBack();
    } catch (e) {
      
    }`;
            const GoForwardScript = `try {
      app.goForward();
    } catch (e) {
      
    }`;*/

            Topbutton.acroField.getWidgets().forEach((widget) => {
              const fieldFlags = widget.dict.get(PDFName.of("F"));
              if (fieldFlags) {
                widget.dict.delete(PDFName.of("F"));
              }
              widget.dict.set(
                PDFName.of("AA"),
                pdfDoc.context.obj({
                  U: {
                    Type: "Action",
                    S: "JavaScript",
                    JS: PDFHexString.fromText(helloWorldScript),
                  },
                })
              );
            });
            /*  Backbutton.acroField.getWidgets().forEach((widget) => {
              const fieldFlags = widget.dict.get(PDFName.of("F"));
              if (fieldFlags) {
                widget.dict.delete(PDFName.of("F"));
              }
              widget.dict.set(
                PDFName.of("AA"),
                pdfDoc.context.obj({
                  U: {
                    Type: "Action",
                    S: "JavaScript",
                    JS: PDFHexString.fromText(GoBackScript),
                  },
                })
              );
            });
            Forwardbutton.acroField.getWidgets().forEach((widget) => {
              const fieldFlags = widget.dict.get(PDFName.of("F"));
              if (fieldFlags) {
                widget.dict.delete(PDFName.of("F"));
              }
              widget.dict.set(
                PDFName.of("AA"),
                pdfDoc.context.obj({
                  U: {
                    Type: "Action",
                    S: "JavaScript",
                    JS: PDFHexString.fromText(GoForwardScript),
                  },
                })
              );
            });*/
          }
        }
      }
    }

    addBookmarks(pdfDoc, PageNumbers);

    for (const pageInfo of annotations) {
      const page = pages[pageInfo.page - 1]; // Adjust page index
      //const pageAnnotations = page.node.get(PDFName.of("Annots"));//gets annot using only pdf-lib
      //console.log(page.node.Annots());

      for (const annot of pageInfo.annotations) {
        if (
          annot.subtype === "Square" &&
          annot.contentsObj.str.length <= 9 &&
          annot.contentsObj.str.includes("-") &&
          PageNumbers.includes(annot.contentsObj.str.trim())
        ) {
          const q = annot.rect;
          // Handle coordinate transformation manually
          const width = Math.abs(q[2] - q[0]);
          const height = Math.abs(q[3] - q[1]);
          const left = Math.min(q[0], q[2]);
          const top = Math.min(q[1], q[3]);
          const buttnname = annot.contentsObj.str + Math.random();
          const button = form.createButton(buttnname);
          // Create a button with JavaScript action
          const buttonText = annot.contentsObj?.str || "Default Text";
          //createPageLinkAnnotation(pdfDoc, annot.rect, page, clr);
          button.addToPage("", page, {
            x: left + 5,
            y: top + 5,
            width: width - 3,
            height: height - 3,
            borderColor: rgb(1, 0, 0), // No border color (transparent)
            borderWidth: 4,
          });

          const helloWorldScript = `getField("Top${annot.contentsObj.str.trim()}").setFocus();zoomType=zoomtype.fitP`;
          button.acroField.getWidgets().forEach((widget) => {
            ///////////////////
            const mkDict = widget.dict.get(PDFName.of("MK"));
            if (mkDict) {
              mkDict.dict.delete(PDFName.of("BG"));
            }
            const fieldFlags = widget.dict.get(PDFName.of("F"));

            if (fieldFlags) {
              widget.dict.delete(PDFName.of("F"));
            }
            widget.dict.set(
              PDFName.of("AA"),
              pdfDoc.context.obj({
                U: {
                  Type: "Action",
                  S: "JavaScript",
                  JS: PDFHexString.fromText(helloWorldScript),
                },
              })
            );

            const appearanceStream = pdfDoc.context.stream(
              `q 1 0 0 RG 2 w 0 0 ${width - 3} ${height - 3} re S Q`, // No fill, thick red border
              {
                Type: "XObject",
                Subtype: "Form",
                BBox: [0, 0, width, height],
                Resources: {},
              }
            );

            // Add appearance stream to the PDF context
            const appearanceStreamRef = pdfDoc.context.register(appearanceStream);
            widget.dict.set(
              PDFName.of("AP"),
              pdfDoc.context.obj({
                N: appearanceStreamRef,
              })
            );
          });
        }
      }
    }

    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
  };
  const handleDocumentLoad = (e) => {
    e.file.name = orgPDFName;
    //setPdfDoc(e.doc); // Save the document object when it loads
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropzoneRef.current.classList.add("dragover");
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();
    dropzoneRef.current.classList.remove("dragover");
  };

  const downloadPdf = (pdfBytes) => {
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    setPdfUrl(url);
    loadPdf(url);
    /* const a = document.createElement("a");
    a.href = url;
    a.download = "modified.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);*/
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <BaseLayout
      title={"Batch Linker"}
      breadcrumb={[{ label: "Batch Linker", route: `/BatchLink/` }, { label: "Batch Linker" }]}
    >
      <MKBox style={{ position: "relative" }}>
        <MKTypography type="h3">
          Upload A Waltek PDF Shop drawing to generate Page links. The following criteria must be
          adheared to ensure all links are handled correctly, PDF must be output directly from
          AutoCAD with AutoCad varible &quot;PDFSHX&quot; set to 1, PDF page name must be less than
          9 characters and follow a format of (Only letters)-(only Numbers)
          {
            " e.g. MTLP-1005 or A-1234567.  Invaild page name examples would be ME1-101, E-101A. Dash is required. The search area for the page name is the bottom right corner of the page when viewed in landscape, the search area is 1/15th of the page width, the search height is 1/15th of the page height. This allows for mixed page sizes."
          }
        </MKTypography>
        <div
          className="file-dropzone"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          ref={dropzoneRef}
        >
          <input type="file" multiple onChange={handleFileChange} className="file-input" />
          <p>Drag & drop files here, or click to select files</p>
        </div>
        <MKBox className="loading-screen">
          <MKTypography type="h2">
            {progressText}... {progress}%
          </MKTypography>
          <progress value={progress} max="100"></progress>
        </MKBox>
        {pdfUrl && (
          <MKBox style={{ position: "relative" }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfUrl}
                onDocumentLoad={handleDocumentLoad}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </MKBox>
        )}
      </MKBox>
    </BaseLayout>
  );
};

PdfAnnotator.propTypes = {
  pdfUrl: PropTypes.string,
};

export default PdfAnnotator;
