// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import Linkedin from "@mui/icons-material/LinkedIn";

import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/Waltek_logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Waltek Company Ltd.",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/waltekcompany/",
    },
    {
      icon: <Linkedin />,
      link: "https://www.linkedin.com/company/waltek-company-ltd",
    },
  ],
  menus: [
    {
      name: "company",
      items: [{ name: "about us", href: "https://www.waltekltd.com/Home" }],
    },
    {
      name: "help & support",
      items: [{ name: "contact us", href: "https://www.waltekltd.com/contact" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="https://www.waltekltd.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Waltek Company Ltd
      </MKTypography>
      .
    </MKTypography>
  ),
};
