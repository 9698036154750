import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";

const Chat = forwardRef(({ Chatdata, OclData, Primary }, ref) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [Alertmessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  useEffect(() => {
    setMessages(Chatdata);
  }, [Chatdata]);
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));
  const handleClick = (message, severity) => {
    setAlertMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (event, forwardmess = "") => {
    if (event != "") {
      event.preventDefault();
    }
    if ((newMessage.trim() && Primary) || forwardmess !== "") {
      setNewMessage(forwardmess);
      // Example of sending a message
      SendChatMessage(forwardmess);
      handleClick("Message sent successfully!", "success");
    } else if (!Primary) {
      handleClick(
        "You must be the Primary contact in order to add a comment, please follow link from Email sent to Primary contact.",
        "error"
      );
    }
  };

  const SaveSentMessage = async (MsgDate, Contact, Mess) => {
    const url = `https://waltekltd.org/Forms/Update_Opening_Check.php`;
    const postData = new URLSearchParams();
    const data = {};
    data._QC_ID = OclData[1].OC_ID;
    data._QC_Job = OclData[1].OC_Job;
    data.MsgDate = MsgDate;
    data.Currentmsg = Mess;
    data._AuthorName = Contact;
    postData.append("action", "Message"); //change to real reciepient

    /*$data =  $_POST['data'];
		$ID = $data["_QC_ID"];
		$Project = $data["_QC_Job"];
		$MsgDate= $data["MsgDate"];
		$NewMsg = strip_tags($data["Currentmsg"]);
		$User = $data["_AuthorName"];*/

    for (const key in data) {
      if (typeof data[key] === "object" && data[key] !== null) {
        if (Array.isArray(data[key])) {
          data[key].forEach((item, index) => {
            for (const itemKey in item) {
              postData.append(`data[${key}][${index}][${itemKey}]`, item[itemKey]);
            }
          });
        } else {
          for (const subKey in data[key]) {
            if (Array.isArray(data[key][subKey])) {
              data[key][subKey].forEach((item, index) => {
                for (const itemKey in item) {
                  postData.append(`data[${key}][${subKey}][${index}][${itemKey}]`, item[itemKey]);
                }
              });
            } else {
              postData.append(`data[${key}][${subKey}]`, data[key][subKey]);
            }
          }
        }
      } else {
        postData.append(`data[${key}]`, data[key]);
      }
    }
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      ///alert message failed to send
      console.log(error);
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const SendChatMessage = async (forwardmess = "") => {
    const url = `https://waltekltd.org/Forms/SendNotiEmail.php`;
    const postData = new URLSearchParams();
    const sendContacts = JSON.parse(OclData[1].Check_Selected_Contacts);
    postData.append("to", sendContacts.Emails); //change to real reciepient
    postData.append(
      "subject",
      `Waltek LTD / OCL-${OclData[1].OC_ID} | Elevation ${OclData[1].Elevation_Num} New Comment / ${OclData[1].JobName}`
    );
    postData.append("title", `There is a new comment on OCL-${OclData[1].OC_ID}`);
    postData.append("linkname", "View OCL Form");
    postData.append(
      "link",
      `https://waltekltd.org/Forms/Home.php?id2=${OclData[1].OC_ID}#Opening-CheckList-Form/`
    );

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: postData,
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const sendContacts = JSON.parse(OclData[1].Check_Selected_Contacts);
      const data = await response.json();
      const timeChat = formatDate(new Date());
      const messHelp = forwardmess !== "" ? forwardmess : newMessage;
      const newMessageObj = {
        id: messages.length + 1,
        User: sendContacts.Primary, // You can customize this to be dynamic
        Message: messHelp,
        Time: timeChat,
      };
      setMessages([...messages, newMessageObj]);
      setNewMessage("");
      SaveSentMessage(timeChat, sendContacts.Primary, messHelp);
      return data;
    } catch (error) {
      ///alert message failed to send
      console.log(error);
      console.error("Error fetching or processing data:", error);
      return [{}];
    }
  };
  return (
    <Container sx={{ px: { xs: 0, sm: 1, md: 2 } }}>
      <Typography size="large" variant="body2" color="inherit">
        Comments
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "30vh",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.300",
          px: { xs: 0, sm: 1, md: 2 },
          mt: 4,
          bgcolor: "background.paper",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            mb: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {Object.entries(messages).map(([key, message]) => {
            const sendContacts = JSON.parse(OclData[1].Check_Selected_Contacts);
            const isUserMessage = sendContacts.Primary !== message.User;
            return (
              <Box
                key={key}
                sx={{
                  display: "flex",
                  justifyContent: isUserMessage ? "flex-start" : "flex-end",
                }}
              >
                <Paper
                  sx={{
                    p: 2,
                    bgcolor: isUserMessage ? "grey.900" : "info.main",
                    color: "background.paper",
                    borderRadius: 2,
                    maxWidth: "60%", // Adjust as needed
                    textAlign: "left",
                  }}
                >
                  <Typography variant="body1" color="inherit">
                    <strong>{message.User}</strong>
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    {message.Message}
                  </Typography>
                  <Typography variant="caption" color="inherit">
                    {new Date(message.Time).toLocaleString()}
                  </Typography>
                </Paper>
              </Box>
            );
          })}
        </Box>
        <form
          onSubmit={(event) => {
            handleSubmit(event, newMessage);
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              variant="outlined"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              sx={{ mr: 1, color: "info.main" }}
            />
            <Button type="submit" variant="contained" sx={{ mr: 1, color: "common.white" }}>
              Send
            </Button>
          </Box>
        </form>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity}>
            {Alertmessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
});

// Typechecking props for the View
Chat.propTypes = {
  Chatdata: PropTypes.array,
  OclData: PropTypes.object,
  Primary: PropTypes.bool,
};
export default Chat;
