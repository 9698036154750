// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useState, useEffect, useContext } from "react";
import View from "layouts/sections/components/ProductView";
import { useParams } from "react-router-dom";
// Buttons page components
import ProductDetailView from "./components/ProductNavTab/index.js";
import { DataContext } from "../../context";
// Buttons page components code

function Buttons() {
  const { OC } = useContext(DataContext);

  const { id, item } = useParams();
  const [headtitle, settitle] = useState("");

  useEffect(() => {
    settitle(OC[1]?.JobName);
  }, [OC]);
  return (
    <BaseLayout
      title={headtitle + ` | OCL-${item}`}
      breadcrumb={[
        { label: "Opening Checklist Index", route: `/Opening-Check-List/${id}/` },
        { label: "Check List " + item },
      ]}
    >
      <View title={item}>
        <ProductDetailView />
      </View>
    </BaseLayout>
  );
}

export default Buttons;
